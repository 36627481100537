<template>
	<div class="login h-100">
		<div class="container-fluid h-100 py-5">
			<div class="row row-wrapper align-items-center justify-content-center">

				<div class="row main-content bg-success text-center">
					<div class="col-md-4 text-center company__info">
						<span class="company__logo"><img src="@/assets/images/asu-logo.png" alt="" class="w-50"></span>
						<h4 class="company_title">Ain Shams University</h4>
					</div>
					<div class="col-md-8 col-xs-12 col-sm-12 login_form ">
						<div class="container-fluid py-4">
							<div class="row">
								<h2>Log In</h2>
							</div>
							<div class="row text-center">

								<div v-if="this.$store.state.otp" style="padding: 30px;font-weight: 600;">
									Pleas wait ...
								</div>
								<form v-else ref="login" class="form-group">
									<div class="row pt-4" v-if="this.$store.state.loginError != null">
										<div class="alert alert-danger" role="alert">
											These credentials do not match our records.
										</div>
									</div>
									<div class="row">
										<input type="text" name="email" id="email"
											v-model="credentials.email" class="form__input" placeholder="Email" required>
									</div>
									<div class="row">
										<input type="password" name="password" id="password"
											v-model="credentials.password" class="form__input"
											placeholder="Password" required>
									</div>
									<div class="row">
										<input type="submit" value="Submit" class="btn mx-auto"
											@click.prevent="login()">
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'login',
		data() {
			return {
				credentials: {
					email: '',
					password: ''
				}
			}
		},
		methods: {
			login() {
				this.$store.dispatch('login', this.credentials ).then( response => {
					this.$store.dispatch("getCaseData", this.$store.state.case_key)
					this.$router.push({ path: `/`, query: {'exp': this.$store.state.case_key} });
				})
			}
		},
		beforeMount(){
			//get the case key
			this.$store.state.case_key = location.href.split('?exp=')[1];
			this.$store.dispatch( 'setInstructions', '' )
			if(this.$store.state.otp != null){ 
				this.$store.dispatch('login', {'otp': this.$store.state.otp} ).then( response => {
					console.log(4444444);
					this.$store.dispatch("getCaseData", this.$store.state.case_key)
					this.$router.push({ path: `/`, query: {'exp': this.$store.state.case_key} });
				})
			}
		},
	}
</script>

<style scoped>
	.company_title {
		color: #fff !important;
	}

	h2 {
		color: #002148 !important;
	}

	.bg-success {
		background-color: #48D8D8 !important;
	}

	.main-content {
		width: 50%;
		border-radius: 20px;
		box-shadow: 0 5px 5px rgba(0, 0, 0, .4);
		margin: 5em auto;
		padding-right: 0px !important;
		padding-left: 0px !important;
		display: flex;
	}

	.company__info {
		background-color: #48D8D8;
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		color: #002148;
	}

	.fa-android {
		font-size: 3em;
	}

	@media screen and (max-width: 640px) {
		.main-content {
			width: 90%;
		}

		.company__info {
			display: none;
		}

		.login_form {
			border-top-left-radius: 20px;
			border-bottom-left-radius: 20px;
		}
	}

	@media screen and (min-width: 642px) and (max-width:800px) {
		.main-content {
			width: 70%;
		}
	}

	.row>h2 {
		color: #008080;
	}

	.login_form {
		background-color: #fff;
		border-top-right-radius: 20px;
		border-bottom-right-radius: 20px;
		border-top: 1px solid #ccc;
		border-right: 1px solid #ccc;
	}

	form {
		padding: 0 2em;
	}

	.form__input {
		width: 100%;
		border: 0px solid transparent;
		border-radius: 0;
		border-bottom: 1px solid #aaa;
		padding: 1em .5em .5em;
		padding-left: 2em;
		outline: none;
		margin: 1.5em auto;
		transition: all .5s ease;
	}

	.form__input:focus {
		border-bottom-color: #008080;
		box-shadow: 0 0 5px rgba(0, 80, 80, .4);
		border-radius: 4px;
	}

	.btn {
		transition: all .5s ease;
		width: 70%;
		border-radius: 30px;
		color: #002148;
		font-weight: 600;
		background-color: #fff;
		border: 1px solid #48D8D8;
		margin-top: 1.5em;
		margin-bottom: 1em;
	}

	.btn:hover,
	.btn:focus {
		background-color: #48D8D8;
		color: #fff;
	}
</style>